import React from 'react';
import './Home.scss';
import { HeaderNav } from '../shared/HeaderNav/HeaderNav.js'
import { VisitButton } from '../shared/VisitButton/VisitButton.js'
import Footer from '../shared/Footer.js'
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { LEXICON_URL } from '../constants.js'
import Carousel from './Carousel.js'
import Hamburger from '../shared/Hamburger/Hamburger.js'
import { Markdown } from '../Markdown.jsx'

const combinedLexicon = new Lexicon({"en": {
    home: new Lexicon(
      require('./Home.json'), 'en',
            'src/Home/Home.json'),
    visitButton: new Lexicon(
      require('../shared/VisitButton/VisitButton.json'), 'en',
             'src/shared/VisitButton/VisitButton.json'),
    headerNav: new Lexicon(
      require('../shared/HeaderNav/HeaderNav.json'), 'en',
             'src/shared/HeaderNav/HeaderNav.json'),
  }
}, 'en')


class Home extends React.Component {

  render () {
    const is_pp = (process.env.REACT_APP_APPLICATION === 'PP')
    const lexicon = this.props.lexicon

    return (
      <div id="Home">
        <HeaderNav lexicon={lexicon.subset('headerNav')} position="home"/>
        <Hamburger position="home" containerId="Home"/>
        <div className="layout">
          <div className="content">
            <Markdown className="introduction desktop-mobile-only first-line" text={lexicon.get('home.introducing_1')} />
            <Markdown className="introduction desktop-mobile-only" text={lexicon.get('home.introducing_2')} />
            <Markdown className="introduction tablet-only" text={lexicon.get('home.introducing')} />
            {is_pp ? <Markdown className="tool" text={lexicon.get('home.pp_a_tool')} /> : <Markdown className="tool" text={lexicon.get('home.a_tool')} /> }
            <div className="visitDiv">
              <VisitButton lexicon={lexicon.subset('visitButton')} wiggle={true} is_pp={is_pp} />
            </div>
            <div className="disclosure">
              <Markdown text={lexicon.get('home.disclosure')} />
            </div>
          </div>
          <div className="carousel">
            <Carousel />
          </div>
        </div>
        <div className="footer">
          <Footer />
        </div>
      </div>
    );
  }

}

const EditableHome = () => (
  <EditWrapper
    component={Home}
    lexicon={combinedLexicon}
    apiUpdateUrl={LEXICON_URL}
  />
);

export default EditableHome;

import React from 'react';
import './Carousel.scss';
import Slider from "react-slick";

let tabletPic1 = null;
let tabletPic2 = null;
let tabletPic3 = null;
let tabletPic4 = null;
let tabletPic5 = null;

const is_pp = (process.env.REACT_APP_APPLICATION === 'PP')

if (is_pp) {
  tabletPic1 = require('./pp-homeTablet1.png')
  tabletPic2 = require('./pp-homeTablet2.png')
  tabletPic3 = require('./pp-homeTablet3.png')
  tabletPic4 = require('./pp-homeTablet4.png')
  tabletPic5 = require('./pp-homeTablet5.png')
} else {
  tabletPic1 = require('./homeTablet1.png')
  tabletPic2 = require('./homeTablet2.png')
  tabletPic3 = require('./homeTablet3.png')
  tabletPic4 = require('./homeTablet4.png')
  tabletPic5 = require('./homeTablet5.png')
}

class Carousel extends React.Component {

  play() {
    this.slider.slickPlay();
  }
  
  pause() {
    this.slider.slickPause();
  }

  render () {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      fade: true,
      speed: 500,
      autoplaySpeed: 5000,
      cssEase: "linear",
      autoplay: true,
      arrows: false,
      draggable: false,
    };
    
    return (
      <div id="Carousel">
        <Slider {...settings}>
          <div>
              <img src={tabletPic1} alt="My Birth Control pic 1" />
          </div>
          <div>
             <img src={tabletPic2} alt="My Birth Control pic 2" />
          </div>
          <div>
             <img src={tabletPic3} alt="My Birth Control pic 3" />
          </div>
          <div>
             <img src={tabletPic4} alt="My Birth Control pic 4" />
          </div>
          <div>
             <img src={tabletPic5} alt="My Birth Control pic 5" />
          </div>
        </Slider>
      </div>
    );
  }
}

export default Carousel;

import React from 'react';
import './VisitButton.scss';

export function VisitButton (props) {
  return (
    <div id="VisitButton">
      <div className="visit">
        <a href={props.is_pp ? "https://postpartum.mybirthcontrol.org/" : "https://clinic.mybirthcontrol.org/"} 
           className="visitButton" 
           target="_blank" 
           rel="noopener noreferrer">
          <span className="visitText">
            {props.lexicon.get('visit_mybc')}
          </span>
          <div className={`arrow-right${props.wiggle ? ' wiggle' : ''}`}></div>
        </a>
      </div>
    </div>
  );
}


import React from 'react';
import './Footer.scss';
import UCSFIcon from './ucsf-logo@2x.png'
import PCRHPIcon from './pcrhp-logo@2x.png'
import PTBIIcon from './ptbi-logo@2x.png'

function Footer() {
  const is_pp = (process.env.REACT_APP_APPLICATION === 'PP')
  const className = is_pp ? "pp" : "";

  return (
    <div id="Footer">
      <img className={className} src={UCSFIcon} alt="UCSF logo" />
      <img className={className} src={PCRHPIcon} alt="PCRHP logo" />
      {is_pp && <img className={className} src={PTBIIcon} alt="PTBI logo" />}
    </div>
  );
}

export default Footer;

import React from 'react';
import {Router, Route} from 'react-router-dom'
import Home from './Home/Home.js'
import About from './About/About.js'
import Patients from './Patients/Patients.js'
import Providers from './Providers/Providers.js'
import history from './history'

function App() {
  return (
    <Router history={history} >
      <Route exact path='/' render={() => <Home />} />
      <Route exact path='/providers' render={() => <Providers />} />
      <Route exact path='/patients' render={() => <Patients />} />
      <Route exact path='/about' render={() => <About />} />
    </Router>
  );
}

export default App;

import React from 'react';
import './Accordion.scss';
import { Markdown } from '../Markdown.jsx'
import PDFIcon from '../shared/PDFIcon.png'
import ThingsTablet from '../ThingsTablet.png'
import ProfileTablet from '../ProfileTablet.png'

export class Accordion extends React.Component {

  constructor(props) {
    super(props)
    this.myRef1 = React.createRef(); 
    this.myRef2 = React.createRef();
    this.myRef3 = React.createRef();
    this.myRef4 = React.createRef();
    this.myRef5 = React.createRef();
    this.myRefPp = React.createRef();

    this.state = {
      openSection: null,
      scrollRef: null
    }
  }

  componentDidUpdate() {
    if (this.state.scrollRef) this.scrollToMyRef(this.state.scrollRef);
  }

  handleAccordionClick = (section, buttonNumber) => {
    if (section === this.state.openSection) {
      this.setState({openSection: null, scrollRef: null})
    } else {
      this.setState({openSection: section, scrollRef: buttonNumber})
    }
  }

  scrollToMyRef = (ref) => {
    switch (ref) {
      case null:
        break;
      case 1:
        window.scrollTo(0, this.myRef1.current.offsetTop)
        break;
      case 2:
        window.scrollTo(0, this.myRef2.current.offsetTop)
        break;
      case 3:
        window.scrollTo(0, this.myRef3.current.offsetTop)
        break;
      case 4:
        window.scrollTo(0, this.myRef4.current.offsetTop)
        break;
      case 5:
        window.scrollTo(0, this.myRef5.current.offsetTop)
        break;
      case 6:
        window.scrollTo(0, this.myRefPp.current.offsetTop)
        break;
      default:
    }
  }
    
  render() {
    const {openSection} = this.state;
    
    // Mikes, TODO: Replace this with nice loop
    const section1Class = "panel section1" + (openSection === "section1" ? " active" : "")
    const section2Class = "panel section2" + (openSection === "section2" ? " active" : "")
    const section3Class = "panel section3" + (openSection === "section3" ? " active" : "")
    const section4Class = "panel section4" + (openSection === "section4" ? " active" : "")
    const section5Class = "panel section5" + (openSection === "section5" ? " active" : "")
    const sectionPpClass = "panel section-pp" + (openSection === "section-pp" ? " active" : "")

    const button1Class = "button button1" + (openSection === "section1" ? " active" : "")
    const button2Class = "button button2" + (openSection === "section2" ? " active" : "")
    const button3Class = "button button3" + (openSection === "section3" ? " active" : "")
    const button4Class = "button button4" + (openSection === "section4" ? " active" : "")
    const button5Class = "button button5" + (openSection === "section5" ? " active" : "")
    const buttonPpClass = "button button-pp" + (openSection === "section-pp" ? " active" : "")

    const arrow1Class = (openSection === "section1" ? " arrow-up" : "arrow-down")
    const arrow2Class = (openSection === "section2" ? " arrow-up" : "arrow-down")
    const arrow3Class = (openSection === "section3" ? " arrow-up" : "arrow-down")
    const arrow4Class = (openSection === "section4" ? " arrow-up" : "arrow-down")
    const arrow5Class = (openSection === "section5" ? " arrow-up" : "arrow-down")
    const arrowPpClass = (openSection === "section-pp" ? " arrow-up" : "arrow-down")

    return (
      <div id="Accordion">
        <button ref={this.myRef1} className={button1Class} onClick={() => {this.handleAccordionClick("section1", 1)}}>
          {this.props.lexicon.get('panel_1_name')}
          <div className={arrow1Class}/>
        </button>
        <div className={section1Class}>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_1_contents')} />
          <Markdown className="textPart" text={this.props.lexicon.get('panel_1_contents_2')} />
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_1_pdf_1_url')} target="_blank" rel="noopener noreferrer"><Markdown text={this.props.lexicon.get('panel_1_pdf_1_text')}/></a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_1_pdf_2_url')} target="_blank" rel="noopener noreferrer"><Markdown text={this.props.lexicon.get('panel_1_pdf_2_text')}/></a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_1_pdf_3_url')} target="_blank"  rel="noopener noreferrer"><Markdown text={this.props.lexicon.get('panel_1_pdf_3_text')}/></a>
          </div>
        </div>

        <button ref={this.myRef2}  className={button2Class} onClick={() => {this.handleAccordionClick("section2", 2)}}>
          {this.props.lexicon.get('panel_2_name')}
          <div className={arrow2Class}/>
        </button>
        <div className={section2Class}>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_2_contents_1')} />
          <div className="flowchart top">
            <div className="dark-orange rectangle" />
            <div className="flowchart-text">
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_1_header')} />
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_1_body')} />
            </div>
          </div>
          <div className="arrow " />
          <div className="flowchart">
            <div className="light-orange rectangle" />
            <div className="flowchart-text">
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_2_header')}/>
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_2_body')} />
            </div>
          </div>
          <div className="arrow " />
          <div className="flowchart">
            <div className="dark-blue rectangle" />
            <div className="flowchart-text">
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_3_header')} />
            </div>
          </div>
          <div className="arrow " />
          <div className="flowchart bottom">
            <div className="light-blue rectangle" />
            <div className="flowchart-text">
              <Markdown className="textPart" text={this.props.lexicon.get('panel_2_flow_chart_4_header')} />
            </div>
          </div>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_2_contents_2')} />
        </div>

        <button ref={this.myRef3}  className={button3Class} onClick={() => {this.handleAccordionClick("section3", 3)}}>
          {this.props.lexicon.get('panel_3_name')}
          <div className={arrow3Class}/>
        </button>
        <div className={section3Class}>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_3_contents_1')} />
          <div className="section3Images">
            <img src={ThingsTablet} alt="Things Tablet" />
          </div>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_3_contents_2')}/>
          <div className="section3Images">
            <img src={ProfileTablet} alt="Profile Tablet" />
          </div>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_3_contents_3')} />
        </div>

        <button ref={this.myRef4}  className={button4Class} onClick={() => {this.handleAccordionClick("section4", 4)}}>
          {this.props.lexicon.get('panel_4_name')}
          <div className={arrow4Class}/>
        </button>
        <div className={section4Class}>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_4_contents')} />
          <Markdown className="textPart" text={this.props.lexicon.get('panel_4_contents_2')} />
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_6_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_6_text')}</a>
          </div>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_4_contents_3')} />
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_7_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_7_text')}</a>
          </div>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_4_contents_4')} />
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_1_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_1_text')}</a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_2_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_2_text')}</a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_3_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_3_text')}</a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_4_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_4_text')}</a>
          </div>
          <div className="pdfLink">
            <img src={PDFIcon} alt="PDF Icon" />
            <a href={this.props.lexicon.get('panel_4_pdf_5_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_4_pdf_5_text')}</a>
          </div>
        </div>

        { this.props.is_pp &&
          <div>
            <button ref={this.myRefPp}  className={buttonPpClass} onClick={() => {this.handleAccordionClick("section-pp", 6)}}>
              {this.props.lexicon.get('panel_pp_name')}
              <div className={arrowPpClass}/>
            </button>
            <div className={sectionPpClass}>
              <Markdown className="textPart" text={this.props.lexicon.get('panel_pp_contents_1')} />
              <div className="pdfLink">
                <img src={PDFIcon} alt="PDF Icon" />
                <a href={this.props.lexicon.get('panel_pp_pdf_1_url')} target="_blank" rel="noopener noreferrer">{this.props.lexicon.get('panel_pp_pdf_1_text')}</a>
              </div>
            </div>
          </div>
        }

        <button ref={this.myRef5}  className={button5Class} onClick={() => {this.handleAccordionClick("section5", 5)}}>
          {this.props.lexicon.get('panel_5_name')}
          <div className={arrow5Class}/>
        </button>
        <div className={section5Class}>
          <Markdown className="textPart" text={this.props.lexicon.get('panel_5_contents_2')} />
        </div>
      </div>
    );
  }
}


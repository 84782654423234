import React from 'react';
import './Patients.scss';
import { HeaderNav } from '../shared/HeaderNav/HeaderNav.js'
import { VisitButton } from '../shared/VisitButton/VisitButton.js'
import Footer from '../shared/Footer.js'
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { Markdown } from '../Markdown.jsx'
import Hamburger from '../shared/Hamburger/Hamburger.js'
import { LEXICON_URL } from '../constants.js'

let laptopPic = null;
let phonePic = null;
const is_pp = (process.env.REACT_APP_APPLICATION === 'PP')

if (is_pp) {
  laptopPic = require('./pp-PatientsLaptop.png')
  phonePic = require('./pp-PatientsPhone.png')
} else {
  laptopPic = require('./PatientsLaptop.png')
  phonePic = require('./PatientsPhone.png')
}

function Patients(props) {

  const lex = props.lexicon

  return (
    <div id="Patients">
      <HeaderNav lexicon={props.lexicon.subset('headerNav')} position="patients"/>
      <Hamburger position="patients" />

      <div className="outer-layout">
        <div className="layout">
          <div className="row-or-column">
            <div className="content">
              <Markdown className="for_patients" text={lex.get('patients.for_patients')} />
              <Markdown className="choosing" text={lex.get('patients.choosing')} />
            </div>
            <div className="logo mobile-tablet-only">
              <img src={laptopPic} className="laptopPic" alt="Laptop pic" />
              <img src={phonePic} className="phonePic" alt="Phone pic" />
            </div>
          </div>
          <div className="more-content">
            <Markdown className="section_head" text={lex.get('patients.what_is')} />
            <Markdown className="explanatory" text={lex.get('patients.online_tool')} />
            { is_pp ?
              <div>
                <Markdown className="explanatory" text={lex.get('patients.pp_info_list')} /> 
                <Markdown className="explanatory continue" text={lex.get('patients.info_list')} />
              </div> :
              <div>
                <Markdown className="explanatory" text={lex.get('patients.info_list')} /> 
              </div> 
            }
            <Markdown className="explanatory" text={lex.get('patients.questions')} />
            <Markdown className="explanatory" text={lex.get('patients.profile')} />
            <Markdown className="what_can_i_do section_head" text={lex.get('patients.what_can_i_do')} />
            <Markdown className="explanatory" text={lex.get('patients.visit_mybc')} />
          </div>
          <div className="visitDiv">
            <VisitButton lexicon={lex.subset('visitButton')} />
          </div>
        </div>

        <div className="logo desktop-only">
          <img src={laptopPic} className="laptopPic" alt="Laptop pic" />
          <img src={phonePic} className="phonePic" alt="Phone pic" />
        </div>

      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

const combinedLexicon = new Lexicon({en: {
  patients: new Lexicon(require('./Patients.json'), 'en', 'src/Patients/Patients.json'),
  headerNav: new Lexicon(require('../shared/HeaderNav/HeaderNav.json'), 'en', 'src/shared/HeaderNav/HeaderNav.json'),
  visitButton: new Lexicon(require('../shared/VisitButton/VisitButton.json'), 'en', 'src/VisitButton/VisitButton.json')
}}, 'en')

const EditablePatients = () => (
  <EditWrapper
    component={Patients}
    lexicon={combinedLexicon}
    apiUpdateUrl={LEXICON_URL}
  />
);

export default EditablePatients;

import React from 'react';
import lodash from 'lodash';

var md = require('markdown-it')('commonmark');

class MarkdownIt extends React.Component {
    getMarkdownText(text) {
      var rawMarkup = md.render(text);
      return { __html: rawMarkup };
    }

    render() {
      if (! lodash.isString(this.props.text)){
        console.error('Expected string as "text" prop, but received:', this.props.text)
      }

      return <div className={this.props.className} dangerouslySetInnerHTML={this.getMarkdownText(this.props.text)} />
    }
  }

export {MarkdownIt as Markdown}

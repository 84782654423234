import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-router-dom';
import './Hamburger.scss';
import HamburgerIcon from './Hamburger.png';
import CloseIcon from './Close.png';
import MyBCIcon from './MyBirthControl.png';
import RainbowBar from '../RainbowBar.js'

class Hamburger extends React.Component {

  render () {
    return (
      <div id="Hamburger">
        <div className="top-bar">
          <RainbowBar />
        </div>
        <div className="mybc">
          <img src={MyBCIcon} alt="My Birth Control Icon" />
        </div>
        <Menu right customBurgerIcon={<img src={HamburgerIcon} alt="Hamburger" />} 
                    burgerButtonClassName={"custom-button"} 
                    customCrossIcon={ <img src={CloseIcon} alt="Close" />} 
                    crossButtonClassName={"custom-cross-button"}
                    menuClassName={"custom-menu"}
                    pageWrapId={"dummyItem"} outerContainerId={"Hamburger"}
                    width = {'100%'}>
          <div className="mybc-item-list">
            <div className="top-bar fixed">
              <RainbowBar />
            </div>
            <div className="mybc in-menu">
              <img src={MyBCIcon} alt="My Birth Control Icon" />
            </div>
            <Link id="home" to="/" className={`menu-item ${this.props.position}`}>
              Home
              <hr />
            </Link>
            <Link id="providers" to="/providers" className={`menu-item ${this.props.position}`}>
              <div className="two-lines">
                <span className="first-row">
                For Providers
                </span>
                <span className="second-row">
                &amp; Clinics
                </span>
              </div>
              <hr />
            </Link>
            <Link id="patients" to="/patients" className={`menu-item ${this.props.position}`}>
              For Patients
              <hr />
            </Link>
            <Link id="about" to="/about" className={`menu-item ${this.props.position}`}>
              About Us
              <hr />
            </Link>
          </div>
          <RainbowBar size="small"/>
        </Menu>
        <div id="dummyItem" />
      </div>
    );
  }
}

export default Hamburger;
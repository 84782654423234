import React from 'react';
import './RainbowBar.scss';

function RainbowBar(props) {
  return (
    <div id="RainbowBar">
      <div className={`headerRainbow ${props.size}`}>
        <div className="lightOrange" />
        <div className="orange" />
        <div className="lightGreen" />
        <div className="green" />
        <div className="lightBlue" />
        <div className="blue" />
      </div>
    </div>
  );
}

export default RainbowBar;
import React from 'react';
import './About.scss';
import { HeaderNav } from '../shared/HeaderNav/HeaderNav.js'
import Footer from '../shared/Footer.js'
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { LEXICON_URL } from '../constants.js'
import { Markdown } from '../Markdown.jsx'
import ConvergeLogo from './converge@2x.png'
import PTBILogo from './ptbi@2x.png'
import AboutPic from './PCRHP.png'
import Hamburger from '../shared/Hamburger/Hamburger.js'

function About(props) {
  return (
    <div id="About">
      <HeaderNav lexicon={props.lexicon.subset('headerNav')} position="about"/>
      <Hamburger position="about" />

      <div className="outer-layout">
        <div className="layout">
          <div className="row-or-column">
            <div className="content">
              <Markdown className="about_us" text={props.lexicon.get('about.about_us')} />
              <Markdown className="explanatory" text={props.lexicon.get('about.about_text_1')} />
            </div>
            <div className="logo mobile-tablet-only">
              <a href="https://fcm.ucsf.edu/person-centered-reproductive-health-program">
                <img src={AboutPic} alt="Reproductive health pic" />
              </a>
            </div>
          </div>
          <div className="more-content">
           <Markdown className="explanatory" text={props.lexicon.get('about.about_text_2')} />
           <Markdown className="partners" text={props.lexicon.get('about.partners')} />
           <div className="partnerLogos">
             <a href="http://www.convergeaccess.org">
               <img src={ConvergeLogo} className="converge" alt="Converge logo" />
             </a>
             <a href="https://www.pretermbirth.ucsf.edu/">
               <img src={PTBILogo} className="ptbi" alt="PTBI logo" />
             </a>
           </div>
          </div>
        </div>
        <div className="logo desktop-only">
          <a href="https://fcm.ucsf.edu/person-centered-reproductive-health-program">
            <img src={AboutPic} alt="Reproductive health pic" />
          </a>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

const combinedLexicon = new Lexicon({en: {
  about: new Lexicon(require('./About.json'), 'en', 'src/About/About.json'),
  headerNav: new Lexicon(require('../shared/HeaderNav/HeaderNav.json'), 'en', 'src/shared/HeaderNav/HeaderNav.json')
}}, 'en')

const EditableAbout = () => (
  <EditWrapper
    component={About}
    lexicon={combinedLexicon}
    apiUpdateUrl={LEXICON_URL}
  />
);

export default EditableAbout;

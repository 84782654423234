import React from 'react';
import './Providers.scss';
import { HeaderNav } from '../shared/HeaderNav/HeaderNav.js'
import Footer from '../shared/Footer.js'
import { Accordion } from './Accordion.js'
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { LEXICON_URL } from '../constants.js'
import { Markdown } from '../Markdown.jsx'
import Hamburger from '../shared/Hamburger/Hamburger.js'

let tabletPic = null;
const is_pp = (process.env.REACT_APP_APPLICATION === 'PP')

if (is_pp) {
  tabletPic = require('./pp-providersTablet.png')
} else {
  tabletPic = require('./ProvidersTablet.png')
}

function Providers(props) {

  console.log('intro 1=', props.lexicon.get('providers.intro_1'))

  return (
    <div id="Providers">
      <HeaderNav lexicon={props.lexicon.subset('headerNav')} position="providers"/>
      <Hamburger position="providers" />

      <div className="outer-layout">
        <div className="layout">
          <div className="row-or-column">
            <div className="content">
              <Markdown className="forProviders" text={props.lexicon.get('providers.for_providers')} />
              <Markdown className="myBC" text={props.lexicon.get('providers.my_birth_control')} />
            </div>
            <div className="logo mobile-tablet-only">
              <img src={tabletPic} alt="Tablet pic" />
            </div>
          </div>
          <div className="more-content">
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_1')} />
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_2')} />
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_3')} />
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_4')} />
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_5')} />
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_6')} />
            { is_pp && <Markdown className="explanatory" text={props.lexicon.get('providers.pp_intro')} /> }
            <Markdown className="explanatory" text={props.lexicon.get('providers.intro_7')} />
            <Accordion is_pp={is_pp} lexicon={props.lexicon.subset('accordion')}/>
          </div>
        </div>

        <div className="logo desktop-only">
          <img src={tabletPic} alt="Tablet pic" />
        </div>
      </div>

      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

const combinedLexicon = new Lexicon({en: {
  providers: new Lexicon(require('./Providers.json'), 'en', 'src/Providers/Providers.json'),
  headerNav: new Lexicon(require('../shared/HeaderNav/HeaderNav.json'), 'en', 'src/shared/HeaderNav/HeaderNav.json'),
  accordion: new Lexicon(require('./Accordion.json'), 'en', 'src/Providers/Accordion.json'),
}}, 'en')

const EditableProviders = () => (
  <EditWrapper
    component={Providers}
    lexicon={combinedLexicon}
    apiUpdateUrl={LEXICON_URL}
  />
);

export default EditableProviders;
